<template>
  <div>
    <el-dialog
      title="家庭成员"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :inline="true"
        class="demo-form-inline"
        style="width: 94%; margin-left: 3%"
      >
        <el-form-item v-for="(item, index) in familyMember" :key="index">
          <div
            style="
              border: 1px solid rgb(204, 204, 204);
              margin: 6px;
              border-radius: 12px;
              text-align: center;
              line-height: 0px;
              display: inline-block;
              padding: 10px 12px;
            "
          >
            {{ item.id }}
          </div>
          <span
            @click="familys(item.id)"
            style="padding: 7px; border: 1px solid #ccc; cursor: pointer"
            >{{ item.phone }}
            <!-- v-for="(name,index) in familyNmae" :key="index" -->
            <span>
              <span
                style="
                  padding: 2px 10px;
                  border: 1px solid rgb(204, 204, 204);
                  border-radius: 13px;
                  margin-left: 7px;
                "
                v-if="item.roleName"
                >{{ item.roleName }}</span
              >
            </span> </span
          ><i
            class="el-icon-circle-close"
            style="font-size: 18px; margin-left: 2px; cursor: pointer"
            @click="albumPhoto(item.id)"
          ></i>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
      <el-button @click="administrators">管理员</el-button>
      <el-button @click="contacts">联系人</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      dialogVisible: false,
      centerDialogVisible: false,
      familyMember: [],
      familyName: [],
    };
  },
  methods: {
    //弹框按钮
    familys(val) {
      this.centerDialogVisible = true;
      this.userIds = val;
    },
    //删除家庭成员
    albumPhoto(index) {
      this.$confirm("是否继续删除选中成员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout
            .deleteUsers({ fId: this.$parent.usernumber, userId: index })
            .then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                // this.reload();
                apiShout
                  .getfamUse({ familyId: this.$parent.usernumber })
                  .then((res) => {
                    this.familyMember = res.data.result;
                    // this.familyMember.forEach((item) => {
                    //   if (item.manager == 1) {
                    //     item.familyName = "管理员";
                    //   } else if (item.urgent == 1) {
                    //     item.familyName = "联系人";
                    //   }
                    // });
                  });
              } else {
                this.$message.error("删除失败！");
              }
              this.$parent.usernums();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //修改管理员
    administrators() {
      apiShout
        .updateAdmins({ fId: this.$parent.usernumber, userId: this.userIds })
        .then((res) => {
          if (res.data.code === 200) {
            this.centerDialogVisible = false;
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          } else {
            this.$message.error("修改失败！");
          }
        });
    },
    //修改联系人
    contacts() {
      apiShout
        .updateUrgents({ fId: this.$parent.usernumber, userId: this.userIds })
        .then((res) => {
          if (res.data.code === 200) {
            this.centerDialogVisible = false;
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          } else {
            this.$message.error("修改失败！");
          }
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang='less' scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 20px 20px 40px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
.buttons {
  .el-button {
    width: 145px;
    height: 30px;
    border-radius: 25px;
    margin-left: 20px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 19px;
    padding: 0;
    float: right;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 18px;
  font-weight: bold;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
</style>