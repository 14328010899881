<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top" v-if="isXian">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="80px">
          <el-form-item label="创建时间" style="width: 48%">
            <el-col>
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="家庭昵称">
            <el-col>
              <el-input
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.regionTwo"
                placeholder="请输入"
              >
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="家庭区域">
            <el-col :span="6">
              <el-select
                v-model="SerachInput.province"
                placeholder="选择省份"
                @change="provinces(SerachInput.province)"
              >
                <el-option
                  v-for="item in optionssex"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col
              class="line"
              :span="1"
              style="margin-left: 5px; margin-right: 5px;text-align: center;"
            >
              --</el-col
            >
            <el-col :span="6">
              <el-select
                v-model="SerachInput.city"
                @change="cityOnes(SerachInput.city)"
                placeholder="选择城市"
              >
                <el-option
                  v-for="item in optionssexs"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col
              class="line"
              :span="1"
              style="margin-left: 5px; margin-right: 5px;text-align: center;"
            >
              --</el-col
            >
            <el-col :span="6">
              <el-select v-model="SerachInput.county" placeholder="选择城市">
                <el-option
                  v-for="item in optionssexTwo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="家庭ID">
            <el-input
              v-model="SerachInput.id"
              placeholder="请输入内容"
              style="width: 150px"
              @keyup.enter.native="handleQuery"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="38px">
            <img
              src="../../assets/images/query.png"
              style="width: 49px"
              alt=""
              @click="handleQuery"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="id" label="家庭ID" width="100">
          </el-table-column>
          <el-table-column prop="name" label="家庭名称" width="120">
          </el-table-column>
          <el-table-column prop="createDate" label="创建时间" width="180">
          </el-table-column>
          <!-- <el-table-column prop="serviceEnd" label="服务到期时间" width="160">
          </el-table-column> -->
          <el-table-column prop="address" label="家庭地址" width="230">
          </el-table-column>
          <el-table-column prop="userNum" label="家庭成员" width="110">
            <template slot-scope="scope">
              <el-button
                size="small"
                v-if="scope.row.userNum"
                @click="usernums(scope.$index, scope.row)"
              >
                <span>{{ scope.row.userNum }}</span>
              </el-button>
              <span v-else>{{ scope.row.userNum }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="equipmentNum" label="家庭设备" width="110">
            <template slot-scope="scope">
              <el-button
                size="small"
                v-if="scope.row.equipmentNum"
                @click="equipments(scope.$index, scope.row)"
              >
                <span>{{scope.row.equipmentNum}}</span>
              </el-button>
              <span v-else>{{scope.row.equipmentNum}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="photoNum" label="家庭相册" width="110">
            <template slot-scope="scope">
              <el-button
                size="small"
                v-if="scope.row.photoNum"
                @click="photoNums(scope.$index, scope.row)"
              >
                <span>{{ scope.row.photoNum }}</span>
              </el-button>
              <span v-else>{{ scope.row.photoNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >更改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <p class="bottom-shu">共计{{ totalCount }}条数据</p>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
                @pagination="getEquipment"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据分析</el-button>
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          style="margin-left: 2.6%"
        >
          <el-form-item label="家庭名称" prop="name" style="margin-bottom: 0">
            <el-input
              v-model="formInline.name"
              placeholder="请输入家庭名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="家庭地址">
            <el-select
              v-model="formInline.province"
              @change="provinceTwo(formInline.province)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in Fprovince"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              style="margin-left: 5px"
              v-model="formInline.city"
              @change="citys(formInline.city)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in Fcitys"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              style="margin-left: 5px"
              v-model="formInline.county"
              placeholder="请选择"
              @change="Fcounty(formInline.county)"
            >
              <el-option
                v-for="item in Fcountys"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-input
              style="margin-top: 16px"
              type="textarea"
              v-model="formInline.address"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="preservation">保 存</el-button>
        </span>
      </el-dialog>
    </div>
    <family-member ref="famils"></family-member>
    <home-equipment ref="equipment"></home-equipment>
    <family-album ref="album"></family-album>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
import FamilyAlbum from "./familyAlbum.vue";
import familyMember from "./familyMember.vue";
import HomeEquipment from "./homeEquipment.vue";
export default {
  components: { familyMember, HomeEquipment, FamilyAlbum },
  inject: ["reload"],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      //增加的弹框
      dialogVisible: false,
      isXian: true,
      unitName: [],
      //城市
      Fprovince: [],
      Fcitys: [],
      Fcountys: [],
      //性别下拉
      optionssex: [],
      optionssexs: [],
      optionssexTwo: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
      ],
      overallForm: {
        time: [],
      },
      SerachInput: {
        tiem: null,
        tiems: null,
        type: "",
        id: "",
        regionTwo: "",
      },
      tiem: "",
      tiems: "",
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value: "",
      input: "",
      title: "",
      tableData: [],
      //分页
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      unitNamePage: 0,
      keyIds: [],
      regionsId: null,
      regionsIds: null,
      provinceOne: "",
      cityOne: "",
      countyOne: "",
      cityId: "",
      countyId: "",
      numTrue: false,
      preservations: false,
      //增加的表单
      formInline: {
        id: "",
        createDate: null,
        updateDate: null,
      },
      itemList: [],
    };
  },
  created() {
    this.getEquipment();
    api.AreaCity({ parentId: 0 }).then((res) => {
      this.optionssex = res.data.result; //输出省
    });
    api.AreaCity({ parentId: 0 }).then((res) => {
      this.Fprovince = res.data.result; //输出省2
    });
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //市
    provinces(val) {
      this.ids = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        this.optionssexs = res.data.result; //渲染省
      });
    },
    //市
    provinceTwo(val) {
      debugger;
      this.ids = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        this.Fcitys = res.data.result; //渲染省
      });
    },
    //区
    cityOnes(val) {
      this.cityName = val;
      api.AreaCity({ parentId: this.cityName }).then((res) => {
        this.optionssexTwo = res.data.result; //渲染区
      });
    },
    //区
    citys(val) {
      this.cityName = val;
      this.cityId = val;
      api.AreaCity({ parentId: this.cityName }).then((res) => {
        this.Fcountys = res.data.result; //渲染区
      });
    },
    Fcounty(val) {
      this.countyId = val;
    },
    //导出
    exportList() {},
    handleQuery() {
      this.getEquipment();
    },
    getEquipment() {
      apiShout
        .getFamilys({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          name: this.SerachInput.regionTwo,
          id: this.SerachInput.id,
          city: this.SerachInput.city,
          county: this.SerachInput.county,
        })
        .then((res) => {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
        });
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getEquipment();
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
    },
    //更改
    handleEdit(index, row) {
      // this.formInline.id = row.id;
      debugger;
      this.ByIdsAll = row.id;
      console.log(row, "1111");
      this.cityOne = row.cityCn;
      this.countyOne = row.countyCn;
      this.formInline = row;
      this.numTrue = true;
      if (this.numTrue == true) {
        this.formInline.city = this.cityOne;
        this.formInline.county = this.countyOne;
      }
      // if (this.ByIdsAll != null) {
      this.dialogVisible = true;
      // }
    },
    //家庭成员
    usernums(index, row) {
      console.log('row:',row);
      this.usernumber = row.id;
      this.$refs.famils.dialogVisible = true;
      apiShout.getfamUse({ familyId: this.usernumber }).then((res) => {
        if (res.data.code === 200) {
          res.data.result.forEach((item) => {
            if (item.manager == 1) {
              // item.familyName = item.manager;
              item.familyName = "管理员";
            } else if (item.urgent == 1) {
              // item.familyName = item.urgent;
              item.familyName = "联系人";
            }
          });
          this.$refs.famils.familyMember = res.data.result;
          this.$refs.famils.familyName = res.data.result;
        }
      });
    },
    //家庭设备
    equipments(index, row) {
      this.equipmentNums = row.id;
      this.$refs.equipment.dialogVisible = true;
      apiShout.getFamilyEquipments({ fId: this.equipmentNums }).then((res) => {
        if (res.data.code === 200) {
          this.$refs.equipment.homeEquipment = res.data.result;
        }
      });
    },
    //家庭相册
    photoNums(index, row) {
      console.log('row:',row);
      this.photos = row.id;
      this.$refs.album.dialogVisible = true;
      this.$refs.album.familyAlbum = [];
      apiShout.getFamilyPhotos({ fId: this.photos }).then((res) => {
        if (res.data.code === 200) {
          var arr = res.data.result;
          for (var i = 0; i < arr.length; i++) {
            this.itemList = arr[i].list;
            for (var j = 0; j < this.itemList.length; j++) {
              this.$refs.album.familyAlbum.push(this.itemList[j]);
            }
          }
        }
      });
    },
    //保存
    preservation() {
      this.preservations = true;
      if (this.numTrue == true && this.preservations == true) {
        this.formInline.city = this.cityId;
        this.formInline.county = this.countyId;
      }
      apiShout.updateFamilyBys(this.formInline).then((res) => {
        if (res.data.code === 200) {
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        } else {
          this.$message.error("保存失败！");
        }
        this.getEquipment();
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__label {
  text-align: left !important;
  color: #909399;
}
/deep/.el-form-item {
  display: inline-table;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 20px 20px 40px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 0 0 0;
  margin-right: 40px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
  margin-right: 30px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 15px;
  color: #606266;
  margin-top: 20px;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>