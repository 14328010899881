<template>
  <div>
    <el-dialog
      title="家庭相册"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-form :inline="true" class="demo-form-inline">
        <!--<p v-for="(items,index) in familyDate" :key="index">{{items}}</p>-->
        <el-form-item
          v-for="(item, index) in familyAlbum"
          :key="index"
          style="padding: 10px"
        >
          <p>{{ item.date }}</p>
          <span
            ><img
              @click="imgsAlbums(item)"
              style="width: 200px"
              :src="item.url" /></span
          ><i
            class="el-icon-circle-close"
            style="
              font-size: 18px;
              margin-left: 2px;
              cursor: pointer;
              position: absolute;
              right: -8px;
              top: 32px;
              background: #fff;
              border-radius: 50%;
            "
            @click="familyAlbums(item.id)"
          ></i>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--查看图片-->
    <el-dialog
      title="查看图片"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <span><img style="width: 100%; margin: 0 auto" :src="urls" /></span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  inject: ["reload"],
  data() {
    return {
      dialogVisible: false,
      familyAlbum: [],
      centerDialogVisible: false,
      urls: "",
      familyDate: [],
      itemList: [],
    };
  },
  methods: {
    //查看相册
    imgsAlbums(item) {
      this.centerDialogVisible = true;
      this.urls = item.url;
    },
    familyAlbums(index) {
      this.$confirm("是否继续删除选中相册?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.deletePhotos({ id: index }).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.familyAlbum=[];
              apiShout
                .getFamilyPhotos({ fId: this.$parent.photos })
                .then((res) => {
                  if (res.data.code === 200) {
                    var arr1 = res.data.result;
                    for (var i = 0; i < arr1.length; i++) {
                      this.itemList = arr1[i].list;
                      for (var j = 0; j < this.itemList.length; j++) {
                        this.familyAlbum.push(this.itemList[j]);
                      }
                    }
                  }
                });
              this.$parent.getEquipment();
            } else {
              this.$message.error("删除失败！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    cancel() {
      this.dialogVisible = false;
      // this.reload();
    },
  },
};
</script>

<style lang='less' scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 400px;
  overflow-y: scroll;
}
.top {
  height: 200px;
  margin: 20px 20px 20px 40px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
.buttons {
  .el-button {
    width: 145px;
    height: 30px;
    border-radius: 25px;
    margin-left: 20px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 19px;
    padding: 0;
    float: right;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 18px;
  font-weight: bold;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
</style>