<template>
  <div>
    <el-dialog
      title="家庭设备"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form :inline="true" class="demo-form-inline" style="width: 90%; margin-left: 5%">
        <el-form-item v-for="(item, index) in homeEquipment" :key="index">
          <span
            style="border: 1px solid rgb(204, 204, 204);border-radius: 12px;text-align: center;line-height: 0px;display: inline-block;padding: 10px 12px;"
            >{{ item.id }}</span
          ><span
            style="
              padding: 2px 10px;
              border: 1px solid rgb(204, 204, 204);
              border-radius: 8px;
            "
            >{{ item.typeCn }}</span
          >
          <span style="padding: 7px; border: 1px solid #ccc">{{
            item.imei
          }}</span>
          <i
            class="el-icon-circle-close"
            style="
              font-size: 18px;
              margin-left: 2px;
              cursor: pointer;
              position: absolute;
              right: -7px;
              top: -6px;
              background: #fff;
            "
            @click="HomeEquipments(item.id)"
          ></i>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      dialogVisible: false,
      homeEquipment: {},
    };
  },
  methods: {
    //解绑家庭设备
    HomeEquipments(index) {
      this.$confirm("是否继续解绑选中设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout
            .unbindEquipments({
              fId: this.$parent.equipmentNums,
              equipmentId: index,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "解绑成功!",
                });
                //   this.reload();
                apiShout
                  .getFamilyEquipments({ fId: this.$parent.equipmentNums })
                  .then((res) => {
                    this.homeEquipment = res.data.result;
                  });
              } else {
                this.$message.error("解绑失败！");
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang='less' scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 20px 20px 40px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
.buttons {
  .el-button {
    width: 145px;
    height: 30px;
    border-radius: 25px;
    margin-left: 20px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 19px;
    padding: 0;
    float: right;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 18px;
  font-weight: bold;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
</style>